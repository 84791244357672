<template>
  <div>
    <!-- 不同银行的form填写表单 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogAssianVisibleForm"
      :title="this.bankName + this.assignBankName"
      width="560px"
      destroy-on-close
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        size="small"
        label-width="120px"
      >
        <el-form-item label="授权银行:">
          <el-select
            v-model="form.yhName"
            placeholder="请选择"
            style="width: 200px; margin: 0"
            filterable
          >
            <el-option
              v-for="item in bankBankList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span><i :class="item.icon"></i>{{ item.label }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="登录方式:"
          v-if="form.yhName == '交通银行'"
          prop="bankLoginMethod"
        >
          <el-select
            v-model="form.bankLoginMethod"
            placeholder="请选择"
            style="width: 200px; margin: 0"
            filterable
          >
            <el-option
              v-for="item in loginMethodOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="filterUserName(form.yhName)" prop="userName">
          <input-common
            v-model:modelValue="form.userName"
            style="width: 200px"
          ></input-common>
        </el-form-item>
        <el-form-item label="登录密码:" prop="password">
          <input-common
            v-model:modelValue="form.password"
            style="width: 200px"
          ></input-common>
        </el-form-item>
        <el-form-item
          :label="filterText(form.yhName)"
          v-if="
            [
              '光大银行',
              '宁波银行',
              '建设银行',
              '齐鲁银行',
              '莱商银行',
              '江苏农村商业银行',
              '济宁银行',
              '河北农商银行',
              '承德银行',
              '衡水银行',
              '临商银行',
              '湖北银行',
              '廊坊银行',
              '河北银行',
            ].includes(form.yhName) ||
            (form.yhName == '交通银行' && form.bankLoginMethod == '客户号登录')
          "
          :prop="['河北银行'].includes(form.yhName) ? '': 'czy'"
        >
          <input-common
            v-model:modelValue="form.czy"
            style="width: 200px"
          ></input-common>
        </el-form-item>
        <el-form-item
          label="证书密码："
          v-if="form.yhName == '建设银行'"
          prop="certPass"
        >
          <input-common
            v-model:modelValue="form.certPass"
            style="width: 200px"
          ></input-common>
        </el-form-item>
        <el-form-item label="证书：" v-if="this.form.yhName == '建设银行'">
          <span
            style="
              color: #f56c6c;
              position: relative;
              right: 18%;
              font-size: 13px;
            "
            >*</span
          >
          <el-input
            style="width: 200px; margin-right: 10px; margin-left: -7px"
            v-model="path"
          ></el-input>
          <input
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()"
            >请选择文件</el-button
          >
          <el-button type="primary" @click="uploadExcel" v-loading="loading"
            >上传</el-button
          >
        </el-form-item>
        <span
          @click="getUrl"
          v-if="this.form.yhName == '建设银行'"
          style="
            font-size: 13px;
            color: var(--themeColor, #17a2b8);
            margin-left: 120px;
            cursor: pointer;
          "
          ><el-icon style="position: relative; top: 2px">
            <d-arrow-right /> </el-icon
          >点击查看证书获取方式</span
        >
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="otherBankAssign" size="small"
            ><i class="iconfont icon-baocun" style="margin-right: 2px"></i
            >保存授权</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { agreement } from "@/api/bank";
import { COLLECT_BANK_OPTION } from "@/utils/commonData";
import axios from "axios";
export default {
  name: "accreditBank",
  props: {
    bankName: String,
    bankId: Number,
  },
  data() {
    return {
      bankBankList: COLLECT_BANK_OPTION,
      dialogAssianVisible: false,
      dialogAssianVisibleForm: false,
      agreementLink: "",
      dialogAssianVisibleOpen: false,
      form: {},
      assignBankName: "",
      rules: {
        userName: [
          {
            required: true,
            message: "请输入登录名/客户号/操作员号",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
        czy: [
          {
            required: true,
            message: "请输入操作员/客户号/用户编号",
            trigger: "blur",
          },
        ],
        bankLoginMethod: [
          { required: true, message: "请选择登录方式", trigger: "change" },
        ],
        certPass: [
          { required: true, message: "请输入证书密码", trigger: "change" },
        ],
      },
      comId: null,
      period: "",
      path: "",
      rawFile: "",
      url: "",
      loading: false,
      loginMethodOption: [
        {
          label: "客户号登录",
          value: "客户号登录",
        },
        {
          label: "用户名密码登录",
          value: "用户名密码登录",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    // 弹窗打开时
    init(comId, period, item) {
      this.form.id = item.bankId;
      this.form.comId = comId;
      this.form.period = period;
      if (item.yhName) {
        this.assignBankName = "-" + item.yhName;
        this.form.yhName = item.yhName;
        this.form.userName = item.userName;
        this.form.password = item.password;
        this.form.czy = item.czy;
        this.form.bankNo = item.bankNo;
        this.form.bankLoginMethod = item.bankLoginMethod;
        this.path = item.cert;
        this.form.certPass = item.certPass;
        this.form.cert = item.cert;
      } else {
        this.assignBankName = "";
        this.form.yhName = "";
        this.form.userName = "";
        this.form.password = "";
        this.form.czy = "";
        this.form.cert = "";
        this.form.bankLoginMethod = "";
        this.path = "";
        this.form.certPass = "";
        this.form.cert = "";
      }
      this.dialogAssianVisibleForm = true;
    },

    // 选择授权银行之后的确认按钮
    makeSureOtherAssiagn(param) {
      if (this.form.yhName == "建设银行" && !this.form.cert) {
        this.$qzfMessage("请上传证书", 1);
        return;
      }
      agreement(param).then((res) => {
        if (res.data.msg == "success") {
          this.dialogAssianVisibleForm = false;
          this.$qzfMessage("授权成功");
          this.$emit("success");
        }
      });
    },
    otherBankAssign() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.makeSureOtherAssiagn(this.form);
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    //上传文件
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      const files = this.rawFile;
      const isLt1M = files.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$qzfMessage("请不要上传大于1M的文件。", 1);
        return;
      }
      const param = new FormData(); // 创建form对象
      if (this.rawFile != "") {
        param.append("upFile", this.rawFile, this.rawFile.name);
        param.append("path", "/mnt/bank/cert");
        param.append("name", new Date().getTime() + this.rawFile.name);
      } else {
        this.$message.error("请选择文件");
        return;
      }
      this.loading = true;
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post("https://public.listensoft.net/api/uploadFile", param, config)
        .then((res) => {
          this.loading = false;
          if (res.data.msg == "success") {
            this.$qzfMessage("上传成功");
            this.form.cert = "https://file.listensoft.net" + res.data.data.url;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getUrl() {
      window.open(
        "https://file.listensoft.net/mnt/v2/other/建设银行证书获取方式.pdf"
      );
    },
    filterText(type) {
      if (type == "江苏农村商业银行") {
        return "操作员:";
      } else if (type == "承德银行" || type == "衡水银行") {
        return "企业客户号:";
      } else if (type == "临商银行" || type == "湖北银行") {
        return "用户编号:";
      } else {
        return "客户号/操作员:";
      }
    },
    filterUserName(type) {
      if (type == "江苏农村商业银行" || type == "湖北银行") {
        return "客户号:";
      } else if (type == "中国民生银行") {
        return "操作员号:";
      } else {
        return "用户名:";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 20px;
}
.iconfont {
  font-size: 16px;
  margin-right: 4px;
}
</style>
